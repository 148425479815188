<template>
  <b-form-checkbox v-model="_value" v-on="$listeners" class="check">
    <span>{{ label }}</span>
  </b-form-checkbox>
</template>

<script>
export default {
  name: 'BoxChartCheckBox',
  props: {
    value: {
      type: Boolean,
      default: false,
    },
    label: {
      type: [String, Number],
      default: '',
    },
  },
  computed: {
    _value: {
      get() {
        return this.value
      },

      set(v) {
        if (this.value !== v) this.$emit('input', v)
      },
    },
  },
}
</script>
<style scoped>
.check {
  margin-left: 1px;
}
</style>
